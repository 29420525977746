import * as getters from './Store/Getters'
import * as mutations from './Store/Mutations'
import * as actions from './Store/Actions'

const module = {
    namespaced: true,
    state: () => ({
        user: {},

        token: null,
        tokenExpiresIn: null,

        menus: [],
        activePage: 'login',

        notifications: []
    }),
    getters,
    mutations,
    actions
}

export default module;
