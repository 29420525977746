const routes = [
    {
        path: "/roles",
        name: 'roles',
        component: () => import("../Components/Index.vue"),
        meta: {
            isAuthenticated: true,
            permission: "role_index"
        }
    },
];

export default routes;
