import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/Core/Store"
import LocalStorage from "@/Core/LocalStorage";
//import NProgress from "nprogress";

Vue.use(VueRouter)

// Import
import Auth from "@/Modules/Auth/Route"
import Role from "@/Modules/Role/Route"
import User from "@/Modules/User/Route"
import Ticket from "@/Modules/Ticket/Route"
import Faq from "@/Modules/Faq/Route"

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes: [
        {
            path : '/',
            component: ()=> import('@/Modules/Dashboard/Components/Index'),
            meta: {isAuthenticated: true}
        },
        ...Auth,
        ...Role,
        ...User,
        ...Ticket,
        ...Faq,
        {
            path: '/404',
            component: () => import('@/Layouts/404.vue'),
            meta: {isAuthenticated: true}
        },
        {
            path: '/403',
            component: () => import('@/Layouts/403.vue')
        },
        {
            path: '/500',
            component: () => import('@/Layouts/500.vue')
        },
        {
            path: '*',
            component: () => import('@/Layouts/404.vue')
        },
    ]
})
router.beforeEach((to, from, next) => {

    //NProgress.start(); TODO sayfa geçişlerinde kapalı olmaına rağmen nprogress çıkıyor

    // App Layout
    if (to.matched.some(record => record.meta.isAuthenticated)) {

        if (!LocalStorage.get('access_token')) {
            store.dispatch('auth/clearUserInfoAndToken')

            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        }
    }

    let user = LocalStorage.get('user');

    // Permission
    if (typeof to.meta.permission != 'undefined' && to.meta.permission) {
        if (user.permissions.length == 0) {
            next({path: '/403'})
        }
        let isThere = user.permissions.includes(to.meta.permission);
        if (!isThere) {
            next({path: '/403'})
        }
    }

    next()
})
router.afterEach(() => {
    //NProgress.done()
})
router.onError(error => {
    if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
        window.location.reload()
    }
});

export default router