import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import trApp from '@/Locales/tr/app';
import trValidation from '@/Locales/tr/validation';

import enApp from '@/Locales/en/app';
import enValidation from '@/Locales/en/validation';

export default new VueI18n({
    locale: process.env.VUE_APP_LOCALE || 'tr',
    fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE || 'tr',
    silentTranslationWarn: true,
    messages: {
        tr: {
            ...trApp,
            validation: trValidation
        },
        en: {
            ...enApp,
            validation: enValidation
        },
    }
})
