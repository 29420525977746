import LocalStorage from "@/Core/LocalStorage";

export const setUser = (state, user) => {
    state.user = user;
    LocalStorage.set('user', user);
}

export const setMenus = (state, menus) => {
    state.menus = menus;
    LocalStorage.set('menus', menus);
}

export const clearMenus = (state) => {
    LocalStorage.remove('menus');
    state.menus = []
}

export const setToken = (state, token) => {
    state.token = token;
}

export const setTokenExpiresIn = (state, tokenExpiresIn) => {
    state.tokenExpiresIn = tokenExpiresIn;
}

export const clearUserAndToken = (state) => {
    state.user = {}
    state.token = null;
}

export const setActivePage = (state, page) => {
    state.activePage = page;
}