<template>
    <router-view></router-view>
</template>

<script>
import Service from '@/Services/Auth';

export default {
  name: "App",
  beforeCreate() {
    console.clear();
  },
  created() {
    this.$store.dispatch('initApp').then(()=>{
      this.initTranslations()
    });
  },
  metaInfo() {
    return {
      title: process.env.VUE_APP_PROJECT_TITLE,
      titleTemplate: '%s | ' + process.env.VUE_APP_PROJECT_TITLE,
      htmlAttrs: {
        lang: 'tr'
      },
      meta: [
        { charset: 'utf-8' }
      ]
    }

  },
  methods:{
    initTranslations() {
      const language_lines = this.getWithExpiry("language_lines");
      if (!language_lines) {
        Service.getTranslation()
            .then(response => {
              this.setLanguageLine(response.data.data)
            })
      } else {
        this.setLanguageLine(language_lines)
      }
    },
    setLanguageLine(data) {
      const trApi = [];
      const enApi = [];
      Object.keys(data).forEach(function (key) {
        trApi[key] = data[key].tr;
        enApi[key] = data[key].en;
      })
      const messages = this.$i18n.messages;
      this.$i18n.setLocaleMessage('tr', {
        ...messages.tr,
        api: {
          ...trApi
        }
      })
      this.$i18n.setLocaleMessage('en', {
        ...messages.en,
        api: {
          ...enApi
        }
      })
      this.setWithExpiry("language_lines", data);
    }
  }
}
</script>
