import LocalStorage from "@/Core/LocalStorage";

export const initSidebarExpanded = ({ commit,state }) => {
    let sidebar = LocalStorage.get('sidebarExpanded')
    if(sidebar) {
        commit("setSidebarExpanded", sidebar);
    }else{
        LocalStorage.set('sidebarExpanded',state.sidebarExpanded)
    }
}

export const initFilterDivStatus = ({ commit,state }) => {
    let filterDivStatus = LocalStorage.get('filterDivStatus')
    if(filterDivStatus) {
        commit("setFilterDivStatus", filterDivStatus);
    }else{
        LocalStorage.set('filterDivStatus',state.filterDivStatus)
    }
}

export const initNightMode = ({ commit, state}) => {
    let nightMode = LocalStorage.get('nightMode');
    if(nightMode){
        commit("setNightMode", nightMode);
    }else{
        LocalStorage.set('nightMode',state.nightMode)
    }
}

export const initLocale = ({commit}) => {

    let locale = LocalStorage.get('locale');
    if (locale && ['tr', 'en'].includes(locale)) {
        commit('setLocale', locale);
    }
    else {
        commit('setLocale', 'tr');
    }
}

export const initApp = ({dispatch}) => {
    dispatch('initLocale').then({

    })
    dispatch('initNightMode')
    dispatch('initSidebarExpanded')
    dispatch('initFilterDivStatus')
}