import LocalStorage from "@/Core/LocalStorage";
import i18n from '@/Plugins/i18n'

export const setSidebarExpanded = (state, payload) => {
    state.sidebarExpanded = payload;
    LocalStorage.set('sidebarExpanded',state.sidebarExpanded)
}

export const setFilterDivStatus = (state, payload) => {
    state.filterDivStatus = payload;
    LocalStorage.set('filterDivStatus',state.filterDivStatus)
}

export const setNightMode = (state, payload) => {
    state.nightMode = payload;
    LocalStorage.set('nightMode',state.nightMode)
}

export const setLocale = (state, locale) => {
    i18n.locale = locale
    state.locale = locale;
    LocalStorage.set('locale',state.locale)
}

export const changeSidebarExpanded = (state) => {
    state.sidebarExpanded = !state.sidebarExpanded;
    LocalStorage.set('sidebarExpanded', state.sidebarExpanded);
}

export const changeFilterDivStatus = (state) => {
    state.filterDivStatus = !state.filterDivStatus;
    LocalStorage.set('filterDivStatus', state.filterDivStatus);
}

export const changeNightMode = (state) => {
    state.nightMode = !state.nightMode;
    LocalStorage.set('nightMode', state.nightMode);
}