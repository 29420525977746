import store from '@/Core/Store'

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../Components/Index"),
        meta: { isNotAuthenticated: true }
    },
    {
        path: "/logout",
        component: () => {
            store.dispatch("auth/logout");
        }
    },
    {
        path: "/settings",
        component: () => import("../Components/Settings"),
        meta: { isNotAuthenticated: true }
    },
    {
        path: "/auth",
        component: () => import("../Components/Token")
    }
];

export default routes;
