const translations = {
    tr: "Türkçe",
    en: "English",

    // A
    all : 'All',
    account: 'Account',
    answer:"Answer",

    // B
    branch: 'Branch',
    brand: 'Brand',

    // C
    clear: 'Clear',
    company: 'Company',
    company_name: 'Company Name',
    current_password: "Current Password",
    check_internet: "You are not connected to the Internet. Check your connection.",
    connect_internet: "Connect to the Internet",

    // D
    detailed_search: 'Detailed Search',
    download:'Download',
    delete:'Delete',
    delete_sure:'Are you sure you want to delete?',

    // E
    explanation:'Explanation',
    edit:'Edit',
    email:'Email',
    error: 'Error',
    enter_a_role: "Please select a role",

    // F
    forgot_password:'Forgot password',
    faq:"Faq",

    // H
    help:'Help',

    // I
    id:'ID',

    // L
    logout: 'Logout',
    login:'Login',

    // M
    model:"Model",

    // N
    new: 'New',
    new_password: 'New Password',
    new_password_repeat: 'New Password Again',
    new_add: 'Add New',
    name:'Name',
    no:'No',
    no_result:'No result',

    // O
    other_roles:'Other Roles',
    okey: "Okey",

    // P
    password:'Password',
    phone:'Phone',
    permission_information: "Permission Information",
    password_contains_eight_characters_message: 'Password Contains Eight Characters Message',
    password_contains_uppercase_message:'Password Contains Uppercase Message',
    password_contains_lowercase_message:'Password Contains Lowercase Message',
    password_contains_special_character_message:'Password Contains Special Character Message',
    password_equals_message:'Password Equals Message',
    password_contains_number_message:'Password Contains Number Message',

    // R
    role:'Rol',
    roles:'Roles',
    role_permissions: "Role Permission",
    remember_me:'Remember me',
    return_login:'Return login',

    //Q
    question:"Question",

    // S
    something_went_wrong: "An Error Has Occurred ",
    save:'Save',
    show:'View',
    search:'Search',
    send:'Send',
    settings:'Settings',
    security:'Security',
    surname:'Surname',
    status:'Status',
    subject:'Subject',
    serialNumber:"Serial Number",

    // T
    twofactor:'Two Factor',
    twofactor_authentication:'Two Factor Authentication (2FA)',
    twofactor_authentication_text:'This feature allows you to enable Two-Factor Authentication (2FA) to help protect your account from unauthorized access by requiring an additional code during sign in.',
    ticket:"Ticket",
    time:"Date",

    // U
    update:'Update',
    user_information:"User Information",
    users:"Users",


    // Y
    yes:'Yes',
    you_have_tried_too_many: "You have tried too many.",
    you_can_try_again_after_1_minute: "You can try again after 1 minute.",
    you_must_fill_in_the_fields_indicated_in_red: "Kırmızı ile belirtilen alanları doldurmanız gerekmektedir.",
    your_selected_permission: "Your Selected Permission",

};

export default translations
