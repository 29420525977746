
export const getLocale = (state) => {
    return state.locale;
}

export const getNightMode = (state) => {
    return state.nightMode;
}

export const getSidebarExpanded = (state,commit) => {
    return state.sidebarExpanded;
}

export const getFilterDivStatus = (state,commit) => {
    return state.filterDivStatus;
}