import Vue from "vue"
import Vuex from 'vuex';
import * as getters from './Store/Getters'
import * as mutations from './Store/Mutations'
import * as actions from './Store/Actions'

Vue.use(Vuex)

// Import
import auth from "@/Modules/Auth/Store"

const store = new Vuex.Store({
    state:{
        sidebarExpanded : false,
        nightMode : false,
        locale: 'tr',
        filterDivStatus:false
    },
    getters,
    mutations,
    actions,
    modules:{
        auth
    }
});

export default store