import isset from "isset-php";

export  const getUser = (state) => {
    return state.user;
}

export  const getToken = (state) => {
    return state.token;
}

export  const getTokenExpiresIn = (state) => {
    return state.tokenExpiresIn;
}

export  const getMenus = (state) => {
    return state.menus;
}

export  const getActivePage = (state) => {
    return state.activePage;
}

export  const getNotifications = (state) => {
    return state.notifications;
}

export  const isAuthenticated = (state) => {
    return state.token ? true : false
}

export  const getActiveRole = (commit) => {
    if (isset(() => commit.user.active_role.name)) {
        return commit.user.active_role.name
    }
    return false;
}