import Api from '@/Core/Http';

const login = async (user) => {
    Api.defaults.baseURL = process.env.VUE_APP_AUTH_BASE_URL;
    return Api.post('/auth/login', user);
}
const loginTwoFactor = async (user) => {
    Api.defaults.baseURL = process.env.VUE_APP_AUTH_BASE_URL;
    return Api.post('/auth/two-factor', user);
}

const passwordReset = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.post('/auth/password/reset/info', formData);
}

const passwordResetWithMobileNumberSms = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    const data = {
        mobile_tel: formData.mobileNumber,
        email: formData.email,
        identity: formData.identity
    };
    return Api.post('/auth/password/reset/sms', data);
}
const passwordResetWithMobileNumberSmsVerify = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    const data = {
        token: formData.token,
        sms_code: formData.sms
    };
    return Api.post('/auth/password/reset/verifysms', data);
}
const passwordResetNewPassword = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    const data = {
        token: formData.token,
        password: formData.password,
        password_confirmation: formData.passwordConfirmation
    };
    return Api.post('/auth/password', data);
}

const changePassword = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.put('/auth/password/change',formData);
}

const updateAccount = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.put('/auth/info/update',formData);
}

const getTranslation = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.get('/language/lines');
}

const reset = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.post('/auth/password/reset/mail',data);
}



export default {
    login,
    loginTwoFactor,
    updateAccount,
    passwordReset,
    passwordResetWithMobileNumberSms,
    passwordResetWithMobileNumberSmsVerify,
    passwordResetNewPassword,
    changePassword,
    getTranslation,
    reset
}
