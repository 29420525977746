<template>
  <BButton @click="clicked"
           ref="configedBtn"
           type="button"
           :variant="variant"
           :block="block"
           :disabled="disable"
           :class="{disable}"
  >

    <slot>{{ label }}</slot>
  </BButton>
</template>
<script>
import Vue from 'vue'

export default {
  inheritAttrs: true,
  props: {
    label: {
      default: 'Gönder',
    },
    variant: {
      default: "primary"
    },
    block: {
      default: false
    }
  },
  data() {
    return {
      disable: false
    }
  },
  methods: {
    clicked() {
      Vue.prototype.currentButton = this;
      this.$emit('click')
    }
  }
}
</script>
