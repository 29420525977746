const routes = [
    {
        path: "/users",
        name: 'users',
        component: () => import("../Components/Index.vue"),
        meta: {
            isAuthenticated: true,
            permission: "user_index"
        }
    },
];

export default routes;
