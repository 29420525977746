import Vue from 'vue'
import App from '@/App.vue'
import store from '@/Core/Store'
import router from '@/Core/Router'
import VueMeta from 'vue-meta'
import Toast from 'vue-toastification';
import VueSidebarMenu from 'vue-sidebar-menu'
import VueSweetalert2 from 'vue-sweetalert2';
import { BootstrapVue } from 'bootstrap-vue'
import cloneButton from "@/Components/Elements/CloneButton"

// EventBus
export const EventBus = new Vue();

// Plugin
import i18n from './Plugins/i18n'
import './Plugins/Vee-Validate'

// Style
import '@/Core/Styles'

// Use
Vue.use(router)
Vue.use(VueMeta)
Vue.use(VueSidebarMenu)
Vue.use(Toast, { maxToasts: 5 });
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2,{
  confirmButtonColor: '#e2584c',
  cancelButtonColor: '#ddd',
  icon: 'question',
})

// Component
Vue.component('b-button', cloneButton)

// Helpers
import showErrors, { getErrorMessage } from '@/Helpers/showErrors'

Vue.mixin({ // Plugin içine at mixins ordan çek
  methods:{
    showErrors,
    checkPermission(permission){
      let user = this.$store.getters['auth/getUser'];
      if (!Array.isArray(user.permissions)) {
        return false;
      }
      return user.permissions.includes(permission);
    },
    br2space(str) {
      return str.replace(/<br\s*\/?>/mg, ' ');
    },
    isNull(value) {
      return typeof value != 'undefined' || value !== null || value != '' ? true : false;
    },
    isNotNull(value) {
      return typeof value != 'undefined' && value !== null && value != '' ? true : false;
    },
    isNotNullValue(value, nullValue = '-') {
      return typeof value != 'undefined' && value !== null && value != '' ? value : nullValue;
    },
    isObjectEmpty(object){
      return Object.keys(object).length == 0
    },
    getCacheKey(key){ // TODO language line için kullanılıyor ama neden her role ve route için farklı tanımlansın ki? bi kere tanımlayıp kullanabiliriz
      let user = JSON.parse(localStorage.getItem('user'));
      if(user && user.active_role){
        let pathname= window.location.pathname.split('/')
            .map(function (item){
              if(!isNaN(parseInt(item))){
                return "*";
              }
              return item;
            }).join("/");
        key = user.active_role.name + pathname + '_' + key;
      }
      return key;
    },
    setWithExpiry(key, value, ttl = 3600000) {
      const now = new Date()

      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      key = this.getCacheKey(key);
      localStorage.setItem(key, JSON.stringify(item))
    },
    getWithExpiry(key) {
      key = this.getCacheKey(key);
      const itemStr = localStorage.getItem(key)
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item.value
    }
  }
})

// TODO consoleda 10 tane websocket açılıyor neden
new Vue({
  render: h => h(App),
  store,
  router,
  i18n
}).$mount('#app')
