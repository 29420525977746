import Api from '@/Core/Http';

const usersMe = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.get('/users/me');
}

const menu = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.get('/users/me/menu');
}

const changeRole = async (role) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.put('/users/me/role', {role_id: role.id})
}

const changeBranch = async (branch) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.put('/users/me/branch', {branch_id: branch.id})
}

const loginAs = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.post('/users/login-as', data)
}

const updateUser = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.put('/users/me/update', data)
}

const getAll = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.get('/users', data)
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.post('/users', data)
}

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.delete('/users/'+id)
}

const update = async (id,data) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.put('/users/'+id,data)
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    return Api.get('/users/'+id)
}

export default {
    getAll,
    get,
    store,
    del,
    update,
    usersMe,
    updateUser,
    menu,
    changeRole,
    changeBranch,
    loginAs,
}
