import LocalStorage from "@/Core/LocalStorage";
import router from "@/Core/Router";
import UserService from "@/Services/User";
import isset from "isset-php";

export const initAuthPage = () => {
    let token = LocalStorage.get('access_token');
    if (token) {
        router.push('/');
    }
}

export const initAppPage = ({commit, dispatch}) => {

    let token = LocalStorage.get('access_token');
    let tokenExpiresIn = LocalStorage.get('access_token_expires_in');
    if (token && tokenExpiresIn) {
        let time = new Date().getTime();
        if (time >= +tokenExpiresIn) {
            dispatch('logout')
        }

        dispatch('initUser', token);
        dispatch('initMenus');

        let timerSecond = +tokenExpiresIn - time;
        dispatch('setTimeoutTimer', timerSecond)
    }
}

export const initUser = ({commit, dispatch}, token) => {

    let user = LocalStorage.get('user');
    if (user) {
        commit('setUser', user);
    }

    dispatch('getUser').then(() => {
        commit('setToken', token);
    })
}

export const initMenus = ({commit, dispatch}) => {
    let menus = LocalStorage.get('menus');

    if (menus) {
        commit('setMenus', menus);
    } else {
        dispatch('getMenus');
    }
}

export const  getUser = async ({commit}) => {
    await UserService.usersMe()
        .then(response => {
            let data = response.data.data;
            commit('setUser', data);
        })
}

export const getMenus = ({commit}) => {
    UserService.menu()
        .then(responseUser => {
            let data = responseUser.data.data
            commit('setMenus', data);
        })
}

export const getActiveRole = ({state}) => {
    if (isset(() => state.user.active_role.name)) {
        return state.user.active_role.name
    }
    return false;
}

export const clearUserInfoAndToken = ({commit}) => {
    commit('clearUserAndToken');
    commit('clearMenus');

    LocalStorage.remove('user');
    LocalStorage.remove('access_token');
    LocalStorage.remove('access_token_expires_in');
}

export const logout = ({dispatch}) => {
    dispatch('clearUserInfoAndToken');
    if (router.currentRoute.path != '/login') {
        router.push('/login');
    }
}

export const setTimeoutTimer = ({dispatch}, expiresIn) => {
    /*
     setTimeout(() => {
     dispatch('logout')
     }, expiresIn);
     */
}

export const restoreUserInfo = ({commit, dispatch}, data) => {
    LocalStorage.set('user',data)
}